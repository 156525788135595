<template>
    <v-row 
        class="text-center py-12" 
        justify="center" 
        align="center">
        <v-col cols="12"
            md="4">
            <v-icon 
            class="icon black--text"
            size="70"
            dense>fas fa-check-circle</v-icon>
            <h1 class="display-1 mt-3">
            Nos Valeurs
            </h1>
            <h1 
                :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']" 
                class="font-weight-bold mb-2">
            Politique QSSE
            </h1>
        </v-col>


        <v-col cols="12"
            md="8">
            <v-row>
                <v-col>
                    <v-card
                        color="cyan lighten-3" 
                        elevation="10">
                        <v-card-title class="display-2 justify-center">Bien être</v-card-title>  
                         <h3
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']" 
                            class="grey--text text--darken-3">
                            Epanouissez vous dans l'esprit, le coeur et le corps</h3> 
                    </v-card>
                </v-col>
        
            </v-row>
            
            <v-row>
                <v-col cols="6" > 
                    <v-card 
                        color="cyan lighten-4"
                        height="250"
                        elevation="10">
                        <v-card-title
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']" 
                        class="card-title justify-center"
                        >
                            <span class="font-weight-bold">Q</span>ualité</v-card-title>
                        <h3
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']" 
                        class="grey--text text--darken-3">
                            Satisfaire les clients en termes de Qualité des services offerts</h3> 
                    </v-card>
                </v-col>
                <v-col cols="6" > 
                    <v-card 
                        color="cyan lighten-4"
                        height="250"
                        elevation="10">
                        <v-card-title
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']" 
                        class="card-title justify-center "
                        >
                            <span class="font-weight-bold">S</span>anté</v-card-title>
                        <h3
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']" 
                        class="grey--text text--darken-3">
                            Protéger la Santé de son personnel et celle de ses partenaires</h3> 
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="6" > 
                    <v-card 
                        color="cyan lighten-4"
                        height="300"
                        elevation="10"
                        align="center">
                        <v-card-title
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-2']" 
                        class="card-title justify-center"
                        >
                            <span class="font-weight-bold">S</span>écurité</v-card-title>
                        <h3
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']" 
                        class="grey--text text--darken-3">
                            Agir en toute Sécurité lors de l’accomplissement de ses missions
                             dans ses locaux ou dans ceux de ses partenaires.</h3> 
                    </v-card>
                </v-col>
                
                <v-col cols="6" > 
                    <v-card 
                        color="cyan lighten-4"
                        height="300"
                        elevation="10">
                        <v-card-title
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-2']" 
                        class="card-title justify-center"
                        >
                            <span class="font-weight-bold">E</span>nvironnement</v-card-title>
                            
                        <h3
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-0': 'display-1']" 
                        class="grey--text text--darken-3 ">
                            Protéger votre Environnement</h3> 
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
         
        <v-col
            class="text-center mx-auto mt-5"
            cols="12"
            >
            <v-btn
                class="align-self-end"
                elevation="5"
                fab
                dark
                x-large
                @click="$vuetify.goTo('#pedago')"
                >
                <v-icon>fas fa-chevron-down</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'Qsse',
}
</script>